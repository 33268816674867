import React, { useCallback } from "react";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Collapse, { Panel } from "rc-collapse";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import IconHome from "app/pages/.shared/static/icons/IconHome";
import IconProfil from "app/pages/.shared/static/icons/IconProfil";
import IconHelp from "app/pages/.shared/static/icons/IconHelp";
import IconLogout from "app/pages/.shared/static/icons/IconLogout";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";
import { STRATEGY } from "app/constants";
import { strategyShape } from "app/utils/propTypes";
import NewBadge from "app/pages/Header/conditionnalComponents/NewBadge";
import AmountContainer from "app/pages/.shared/AmountContainer";
import DotNotification from "app/pages/.shared/DotNotification/DotNotification";
import "./AsideMenuAccount.scss";

const AsideMenuAccount = ({
	logout,
	onItemClick,
	creditsAmount,
	hideCreditNotification,
	creditNotesAmount,
	hideCreditNoteNotification,
	userIsConnected,
	enableSponsorship,
	enableCredits,
	isWhiteLabel,
	strategy,
	partnercode,
	showCreditsNotification,
	showCreditNotesNotification,
	shouldDisplayCreditNotesEntry,
	showCustomerRequestNotification,
	onClose,
}) => {
	const handleLogout = useCallback(() => {
		logout();
		onItemClick();
	}, []);

	const handleCreditMenuClick = useCallback(() => {
		if (creditsAmount > 0) {
			hideCreditNotification();
		}
		onItemClick();
	}, [creditsAmount]);

	const handleCreditNoteMenuClick = useCallback(() => {
		if (creditNotesAmount > 0) {
			hideCreditNoteNotification();
		}
		onItemClick();
	}, [creditNotesAmount]);

	const showCreditDotNotification = creditsAmount > 0 && showCreditsNotification;
	const showCreditNoteDotNotification = creditNotesAmount > 0 && showCreditNotesNotification;

	const handleClose = useCallback(() => {
		// onClose permet de surcharger onItemClick
		if (typeof onClose === "function") {
			onClose();
		} else {
			onItemClick();
		}
	}, []);

	return (
		<section className="aside-menu-account">
			<div className="aside-menu-account__header">
				<div className="aside-menu-account__close" onClick={handleClose}>
					<i className="icon icon--cross-white" />
				</div>
				<RelativeLink
					to={{ pathname: "/listing" }}
					className="aside-menu-account__logo"
					onClick={handleClose}
				>
					<LogoBrandContainer isWhite={true} />
				</RelativeLink>
			</div>
			<nav className="aside-menu-account__nav">
				<ul className="nav">
					{strategy !== STRATEGY.PRODUCT_FIRST && (
						<li className="nav__item">
							<RelativeLink to={{ pathname: "/listing" }} onClick={handleClose}>
								<IconHome height={20} style={{ marginRight: "10px" }} />
								<FormattedMessage id="menu.mobile.all.sales" />
							</RelativeLink>
						</li>
					)}
					{(isWhiteLabel || userIsConnected) && (
						<li className="sub-nav">
							<Collapse defaultActiveKey={["0"]}>
								<Panel
									header={
										<div className="sub-nav__main">
											<div>
												<IconProfil
													height={20}
													style={{ marginRight: "10px" }}
												/>
												<FormattedMessage id="menu.mobile.my.account" />
											</div>
											<IconArrowDown height={15} width={15} />
										</div>
									}
								>
									<ul className="sub-nav__list">
										<li className="sub-nav__item">
											<RelativeLink
												to={{ pathname: "/account/profile" }}
												onClick={handleClose}
											>
												<FormattedMessage id="menu.item.my.profile" />
											</RelativeLink>
										</li>

										{enableCredits && (
											<li className="sub-nav__item">
												<RelativeLink
													to={{
														pathname: "/account/mycredits",
													}}
													onClick={handleCreditMenuClick}
												>
													<FormattedMessage
														id={
															creditsAmount > 0
																? "menu.item.my.credits.with.amount"
																: "menu.item.my.credits"
														}
														tagName="span"
														values={{
															creditAmount: (
																<AmountContainer
																	prefix="("
																	suffix=")"
																	amount={creditsAmount}
																/>
															),
														}}
													/>
													{showCreditDotNotification && (
														<DotNotification />
													)}
												</RelativeLink>
											</li>
										)}

										{shouldDisplayCreditNotesEntry && (
											<li className="sub-nav__item">
												<RelativeLink
													to={{ pathname: "/account/myrefunds" }}
													onClick={handleCreditNoteMenuClick}
												>
													<FormattedMessage
														id={
															creditNotesAmount > 0
																? "menu.item.my.refunds.with.amount"
																: "menu.item.my.refunds"
														}
														values={{
															refundAmount: (
																<AmountContainer
																	prefix="("
																	suffix=")"
																	amount={creditNotesAmount}
																/>
															),
														}}
														tagName="span"
													/>
													{showCreditNoteDotNotification && (
														<DotNotification />
													)}
												</RelativeLink>
											</li>
										)}

										{enableSponsorship && (
											<li className="sub-nav__item">
												<RelativeLink
													to={{
														pathname: "/account/sponsorship",
													}}
													onClick={handleClose}
												>
													<FormattedMessage id="menu.item.sponsorship" />
													<NewBadge partnerCode={partnercode} />
												</RelativeLink>
											</li>
										)}

										<li className="sub-nav__item">
											<RelativeLink
												to={{ pathname: "/account/mybookings" }}
												onClick={handleClose}
											>
												<FormattedMessage id="menu.item.my.bookings" />
												{showCustomerRequestNotification && (
													<DotNotification />
												)}
											</RelativeLink>
										</li>
										<li className="sub-nav__item">
											<RelativeLink
												to={{
													pathname: "/account/subscriptions",
												}}
												onClick={handleClose}
											>
												<FormattedMessage id="menu.item.my.subscriptions" />
											</RelativeLink>
										</li>
										<li className="sub-nav__item">
											<RelativeLink
												to={{ pathname: "/account/security" }}
												onClick={handleClose}
											>
												<FormattedMessage id="menu.item.security" />
											</RelativeLink>
										</li>
									</ul>
								</Panel>
							</Collapse>
						</li>
					)}

					<li className="nav__item">
						<RelativeLink
							rel="noopener noreferrer"
							target="_blank"
							to={{ pathname: "/faq" }}
							onClick={handleClose}
						>
							<IconHelp height={20} style={{ marginRight: "10px" }} />
							<FormattedMessage id="menu.item.help" />
						</RelativeLink>
					</li>

					{isWhiteLabel &&
						strategy !== STRATEGY.PRODUCT_FIRST &&
						strategy !== STRATEGY.NAVIGATION_FIRST && (
							<li className="nav__item nav__item--logout">
								<div className="aside-menu-account__bouton">
									<RelativeLink
										to={{ pathname: "/home/login" }}
										onClick={handleLogout}
									>
										<IconLogout height={20} style={{ marginRight: "10px" }} />
										<FormattedMessage id="menu.item.logout" />
									</RelativeLink>
								</div>
							</li>
						)}
				</ul>
			</nav>
		</section>
	);
};

AsideMenuAccount.propTypes = {
	onItemClick: PropTypes.func,
	enableSponsorship: PropTypes.bool,
	enableCredits: PropTypes.bool,
	userIsConnected: PropTypes.bool,
	logout: PropTypes.func,
	isWhiteLabel: PropTypes.bool,
	strategy: strategyShape,
	partnercode: PropTypes.string,
	creditsAmount: PropTypes.number,
	creditNotesAmount: PropTypes.number,
	showCreditsNotification: PropTypes.bool,
	showCreditNotesNotification: PropTypes.bool,
	shouldDisplayCreditNotesEntry: PropTypes.bool,
	hideCreditNotification: PropTypes.func,
	hideCreditNoteNotification: PropTypes.func,
	showCustomerRequestNotification: PropTypes.bool,
	onClose: PropTypes.func,
};

AsideMenuAccount.defaultProps = {
	hideCreditNoteNotification: () => {},
	hideCreditNotification: () => {},
	onItemClick: () => {},
	logout: () => {},
};

export default React.memo(AsideMenuAccount);
